import ApiService from "./ApiService";
import type { PermissionsResponse } from "@/@types/uic";

export async function apiPermission() {
  const resp = await ApiService.fetchData<PermissionsResponse>({
    url: `/ui/uic/v3/permissions`,
    method: "get",
  });

  return resp.data;
}

export async function apiProvisionConnector() {
  const resp = await ApiService.fetchData({
    url: `internal/v2/direct/uic/internal/uic/v3/connectors/saas`,
    method: "post",
    data: {
      productId: "ddr",
    },
  });

  return resp.data;
}
