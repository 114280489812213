import React, { ReactNode, useContext } from "react";
import { Box, Spinner, useColorMode, useColorStyle, useTheme } from "@tonic-ui/react";
import { css, Global } from "@emotion/react";

import { useGetPermission } from "@/hooks/uic/useUicApiQuery";
import { isDevBuild } from "@/utils/index";
import AppContext from "@/contexts/AppContext";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [colorMode] = useColorMode();
  const { fontSizes, lineHeights } = useTheme();
  const { data: userPermissions, isLoading: isUserPermissionLoading } = useGetPermission();
  const { setPermissions, setFlagsState } = useContext(AppContext);

  const checkPermission = (): JSX.Element => {
    if (isDevBuild()) {
      return <Box>{children}</Box>;
    }

    if (isUserPermissionLoading) {
      return <Spinner top="40%" left="50%" size="lg" lineColor="blue:60" position="absolute" />;
    }

    userPermissions?.permissions && setPermissions(userPermissions.permissions);
    userPermissions?.flagsState && setFlagsState(userPermissions.flagsState);

    return <Box>{children}</Box>;
  };

  return (
    <>
      <Global
        styles={css`
          :root,
          :host {
            color-scheme: ${colorMode};
          }

          :focus:not(:focus-visible) {
            outline: none;
          }

          body {
            min-height: 100vh;
            font-size: ${fontSizes.sm};
            line-height: ${lineHeights.sm};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #151515;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family:
              font-family:
              Segoe UI,
              -apple-system,
              BlinkMacSystemFont,
              Helvetica Neue,
              Helvetica,
              Arial,
              sans-serif;
          }
        `}
      />
      {checkPermission()}
    </>
  );
};

export default Layout;
