import Layout from "../layouts/index";
import Route from "../routes/index";
import { AppContextProvider } from "@/contexts/AppContext";

const App = () => {
  return (
    <AppContextProvider>
      <Layout>
        <Route />
      </Layout>
    </AppContextProvider>
  );
};

export default App;
