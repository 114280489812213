import React from "react";
import ReactDOM from "react-dom/client";
import { TonicProvider, ToastManager } from "@tonic-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./pages/index";
import "./index.css";
import "./plugins/l10n";
import "./plugins/pendo";

if (__COMMIT_SHA_SHORT__) {
  window.__COMMIT_SHA_SHORT__ = __COMMIT_SHA_SHORT__;
}

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: import.meta.env.MODE === "dev" ? 1000 : 0,
    },
  },
});

root.render(
  <React.StrictMode>
    <TonicProvider colorMode={{ defaultValue: "dark" }} useCSSBaseline={true}>
      <ToastManager placement="bottom-right">
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ToastManager>
    </TonicProvider>
  </React.StrictMode>,
);
