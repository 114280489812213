import { useQuery, useMutation, UseQueryResult, keepPreviousData } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { apiPermission, apiProvisionConnector } from "@/services/UicService";

export const useGetPermission = (): UseQueryResult<
  Awaited<ReturnType<typeof apiPermission>>,
  AxiosError
> =>
  useQuery({
    queryKey: ["apiPermission"],
    queryFn: () => apiPermission(),
    placeholderData: keepPreviousData,
    retry: false,
  });

export const useCreateProvision = () => {
  return useMutation({
    mutationFn: () => apiProvisionConnector(),
  });
};
