import { useRouteError } from "react-router-dom";
import { Box, Flex, Text } from "@tonic-ui/react";

import WarningIcon from "../../assets/images/warning-icon.svg?react";

const ErrorBoundary = (): JSX.Element => {
  const error = useRouteError() as Error;
  return (
    <Flex direction="column" justifyContent="center" alignItems="center" height="80vh">
      <Box textAlign="center">
        <Box mb="6x">
          <WarningIcon />
        </Box>
        <Box width="440px" textAlign="center">
          <Text fontSize="md" mb="2x">
            Unable to load Data Posture
          </Text>
          <Text color="gray:40">
            An unexpected error has occurred. Please try reloading the page. If the issue persists,
            contact your support provider. ({`${error?.message || "Unknown Error"}`})
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default ErrorBoundary;
