import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "../assets/ddr-web-app-i18n/en.json";
import jpLocale from "../assets/ddr-web-app-i18n/jp.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enLocale,
    },
    ja: {
      translation: jpLocale,
    },
  },
  lng: localStorage.getItem("uic_language") || "en",
  fallbackLng: "en",
  defaultNS: "translation",
  nonExplicitSupportedLngs: true,
  interpolation: {
    escapeValue: true,
  },
  returnEmptyString: false,
  supportedLngs: ["en", "ja"],
  debug: false,
  keySeparator: ".",
});

export default i18n;
