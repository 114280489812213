import { Box, Flex, Spinner } from "@tonic-ui/react";

const OverlayLoader = () => {
  return (
    <Box width="100vw" height="100vh" position="fixed" zIndex="2000" bg="black:tertiary">
      <Flex justify="center" align="center" height="100vh" width="100vw">
        <Spinner size="lg" />
      </Flex>
    </Box>
  );
};

export default OverlayLoader;
