import { createContext, useState } from "react";

import { Permissions, FlagsState } from "@/@types/uic";

export type AppContextProps = {
  permissions: Permissions;
  setPermissions: (permission: Permissions) => void;
  flagsState: FlagsState;
  setFlagsState: (flagState: FlagsState) => void;
};

const AppContext = createContext<AppContextProps>({
  permissions: {
    dataPolicy: {
      view: 1,
      settings: 1,
      export: 1,
      view_data_lineage: 1,
      export_data_lineage: 1,
    },
  },
  setPermissions: () => {},
  flagsState: {
    "ddr-data-policy-private-preview": 1,
  },
  setFlagsState: () => {},
});

export function AppContextProvider({ children }: { children: React.ReactNode }) {
  const [permissions, setPermissions] = useState<Permissions>({
    dataPolicy: {
      view: 1,
      settings: 1,
      export: 1,
      view_data_lineage: 1,
      export_data_lineage: 1,
    },
  });
  const [flagsState, setFlagsState] = useState<FlagsState>({
    "ddr-data-policy-private-preview": 1,
  });

  return (
    <AppContext.Provider
      value={{
        permissions,
        setPermissions,
        flagsState,
        setFlagsState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const AppContextConsumer = AppContext.Consumer;

export default AppContext;
