const initPendo = () => {
  (function (apiKey) {
    if (window.parent._TRACKABLE) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ["initialize", "identify", "updateOptions", "pageLoad"];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://content.analytics.trendmicro.com/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");

      pendo.initialize({
        visitor: {
          id: localStorage.getItem("uic_userGUID"),
          role: localStorage.getItem("uic_roleGUID"),
        },
        account: {
          id: localStorage.getItem("uic_companyGUID"),
        },
      });
    }
  })("e2d0b822-a382-47b1-7fd0-4db539307d92");
};

initPendo();
