import axios from "axios";
const BaseService = axios.create({
  timeout: 60000,
  baseURL: "/",
});

BaseService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("uic-token");
    if (token) {
      config.headers["uic-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("uic-token");
      window.parent.location.reload();
    }

    return Promise.reject(error);
  },
);

export default BaseService;
