import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ErrorBoundary from "../components/ErrorBoundary/index";
import OverlayLoader from "../components/OverlayLoader/index";

const DetectionList = React.lazy(() => import("../pages/DetectionList/index"));
const DataPolicy = React.lazy(() => import("../pages/DataPolicy/List/index"));
const DataPolicyAction = React.lazy(() => import("../pages/DataPolicy/DataPolicyAction"));
const DataLinage = React.lazy(() => import("../pages/DataLinage/index"));

const paths = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <Suspense fallback={<OverlayLoader />}>
          <DetectionList />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/data_lineage",
      element: (
        <Suspense fallback={<OverlayLoader />}>
          <DataLinage />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/data_policy",
      element: (
        <Suspense fallback={<OverlayLoader />}>
          <DataPolicy />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/data_policy_action",
      element: (
        <Suspense fallback={<OverlayLoader />}>
          <DataPolicyAction />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<OverlayLoader />}>
          <DetectionList />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
    },
  ],
  { basename: import.meta.env.VITE_APP_BASE_URL },
);

const Routes = () => {
  return <RouterProvider router={paths} />;
};

export default Routes;
